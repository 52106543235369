import React from 'react';
import {Grid} from '@mui/material';
import {categoriesA as categories} from '../../util/data/static-data';
import Categorycard from '../Cards/JobcategoryCard';

export default function Jobcategory() {
  	// const [jobCategory, setjobCategory] = useState([]);
	return(
		<div id="categorylist">
			{
				categories.length===0||categories===undefined?'':
					<Grid container direction="row" spacing={3} alignItems="center">
						{categories.map((data,i)=>(data.totalJobs==='0'?'':<Grid item md={3} xs><Categorycard category={data} id={i}/></Grid>))}	
					</Grid>
			}
		</div>
	)	
}

// {/* <Grid item md={3} xs> */}
					// 	<p className="bg-white link pointer h3 flex items-center justify-start pl3 br3 tc">{data}<span className="f8"> 100 jobs</span></p>
					// </Grid>