import React from 'react';
import { useHistory } from 'react-router-dom';
import {Grid} from '@mui/material';
import JobSearch from '../Svg/jobsearch.png';
// import Textfield from '../components/FormElements/TextFieldForm'
import Category from '../components/List/Jobcategory';
// import '../styles/Homepage.scss';
import { goToURL } from '../util/helpers/helper-methods';

function Homepage() {
    const history=useHistory()
    console.log(history);
    return (
            <div>
                <Grid container direction="row" alignItems="center" justify="center" className="pb5 pt5 bg-white">
                    <Grid item md={6} xs={12}>
                        <p className='ma2 f1-l mt5 ml5 f2-m f4 pb2 gray tl b'>Get The <span style={{color:"#6EB6FF"}}>Right Job</span><br/> You Deserve</p>
                        <p className='ma1 f5-l mt1 ml5 f6-m f8 pb1 gray tl b'>Apply <span style={{color:"#6EB6FF"}}>Once</span> and get matched to <span style={{color:"#6EB6FF"}}>Many Jobs</span><br/><span style={{color:"#6EB6FF"}}>No Cover letter, No Cost!</span></p>
                        <Grid container direction="row" alignItems="center" justify="center" spacing={1}>
                            <Grid item>
                                <p 
                                    style={{backgroundColor:"#6EB6FF"}} 
                                    className=" ml5 h2 link  f6 pa2 white br2 flex justify-center items-center pointer"
                                    onClick={()=>{goToURL("https://moyyn.com")}}
                                >Know More</p>
                            </Grid>
                            <Grid item>
                                <p 
                                    style={{backgroundColor:"#6EB6FF"}} 
                                    className="link h2 f6 white pa2 br2 flex justify-center items-center pointer"
                                    onClick={()=>{history.push('/jobs')}}
                                >Search Jobs</p>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid md={6} xs={12}>
                        <img className="jobSearchlogo h5 ml5" src={JobSearch} alt="JobSearch"/>
                    </Grid>
                </Grid>
                {/* <Grid container direction="column" alignItems="center" justify="center" className="pt2" style={{backgroundColor:"whitesmoke"}}> */}
                <Grid container direction="column" alignItems="center" justify="center" className="pt2 pb3">
                    <Grid item xs>
                        <p className='ma2 f2-l mt3 f3-m f5 pb2 gray tc b'>One Platform <span style={{color:"#6EB6FF"}}>Many</span> Solution</p>
                    </Grid>
                    <Grid item xs className="flex w-90-1 w-90 items-center justify-center">
                        <Category/>
                    </Grid>
                </Grid>
            </div>
    )
}

export default Homepage

// APply once and get matched to Many Jobs
// No Cover letter, No Cost !