export const categoriesA=[
	{
		jobCategory:"Software Development",
		totalJobs:"15",
		// icon:"CodeIcon"
	},
	{
		jobCategory:"Design",
		totalJobs:"7",
		// icon:"DesignServicesIcon"
	},
	{
		jobCategory:"IT Operations",
		totalJobs:"10",
		// icon:""
	},
	{
		jobCategory:"Data Science & BI",
		totalJobs:"15",
		// icon:""
	},
	{
		jobCategory:"Engineering",
		totalJobs:"9",
		// icon:""
	},
	{
		jobCategory:"Technician/Craftsman",
		totalJobs:"0",
		// icon:""
	},
	{
		jobCategory:"Management",
		totalJobs:"10",
		// icon:""
	},
	{
		jobCategory:"Marketing",
		totalJobs:"10",
		// icon:""
	},
	{
		jobCategory:"Business Development",
		totalJobs:"20",
		// icon:""
	},
	{
		jobCategory:"Sales",
		totalJobs:"5",
		// icon:""
	},
	{
		jobCategory:"Operations & Administration",
		totalJobs:"0",
		// icon:""
	},
	{
		jobCategory:"Technology",
		totalJobs:"6",
		// icon:""
	},
]
export const categories=['','Software Development','Design','IT Operations','Data Science & BI','Engineering','Technician/Craftsman','Management','Marketing','Business Development','Sales','Operations & Administration','Technology']

export const careerLevelOptions = [
	{ name: "", value: "" },
	{ name: "Student", value:"Student"},
	{ name: "Graduate", value: "Graduate" },
	{ name: "Young Professional", value: "Young Professional"},
	{ name: "Senior", value: "Senior" },
	{ name: "Executive", value: "Executive" },
]

export const countriesArray = [ 
	{"name": "Afghanistan", "code": "AF"}, 
	{"name": "land Islands", "code": "AX"}, 
	{"name": "Albania", "code": "AL"}, 
	{"name": "Algeria", "code": "DZ"}, 
	{"name": "American Samoa", "code": "AS"}, 
	{"name": "AndorrA", "code": "AD"}, 
	{"name": "Angola", "code": "AO"}, 
	{"name": "Anguilla", "code": "AI"}, 
	{"name": "Antarctica", "code": "AQ"}, 
	{"name": "Antigua and Barbuda", "code": "AG"}, 
	{"name": "Argentina", "code": "AR"}, 
	{"name": "Armenia", "code": "AM"}, 
	{"name": "Aruba", "code": "AW"}, 
	{"name": "Australia", "code": "AU"}, 
	{"name": "Austria", "code": "AT"}, 
	{"name": "Azerbaijan", "code": "AZ"}, 
	{"name": "Bahamas", "code": "BS"}, 
	{"name": "Bahrain", "code": "BH"}, 
	{"name": "Bangladesh", "code": "BD"}, 
	{"name": "Barbados", "code": "BB"}, 
	{"name": "Belarus", "code": "BY"}, 
	{"name": "Belgium", "code": "BE"}, 
	{"name": "Belize", "code": "BZ"}, 
	{"name": "Benin", "code": "BJ"}, 
	{"name": "Bermuda", "code": "BM"}, 
	{"name": "Bhutan", "code": "BT"}, 
	{"name": "Bolivia", "code": "BO"}, 
	{"name": "Bosnia and Herzegovina", "code": "BA"}, 
	{"name": "Botswana", "code": "BW"}, 
	{"name": "Bouvet Island", "code": "BV"}, 
	{"name": "Brazil", "code": "BR"}, 
	{"name": "British Indian Ocean Territory", "code": "IO"}, 
	{"name": "Brunei Darussalam", "code": "BN"}, 
	{"name": "Bulgaria", "code": "BG"}, 
	{"name": "Burkina Faso", "code": "BF"}, 
	{"name": "Burundi", "code": "BI"}, 
	{"name": "Cambodia", "code": "KH"}, 
	{"name": "Cameroon", "code": "CM"}, 
	{"name": "Canada", "code": "CA"}, 
	{"name": "Cape Verde", "code": "CV"}, 
	{"name": "Cayman Islands", "code": "KY"}, 
	{"name": "Central African Republic", "code": "CF"}, 
	{"name": "Chad", "code": "TD"}, 
	{"name": "Chile", "code": "CL"}, 
	{"name": "China", "code": "CN"}, 
	{"name": "Christmas Island", "code": "CX"}, 
	{"name": "Cocos (Keeling) Islands", "code": "CC"}, 
	{"name": "Colombia", "code": "CO"}, 
	{"name": "Comoros", "code": "KM"}, 
	{"name": "Congo", "code": "CG"}, 
	{"name": "Congo, The Democratic Republic of the", "code": "CD"}, 
	{"name": "Cook Islands", "code": "CK"}, 
	{"name": "Costa Rica", "code": "CR"}, 
	{"name": "Cote D'Ivoire", "code": "CI"}, 
	{"name": "Croatia", "code": "HR"}, 
	{"name": "Cuba", "code": "CU"}, 
	{"name": "Cyprus", "code": "CY"}, 
	{"name": "Czech Republic", "code": "CZ"}, 
	{"name": "Denmark", "code": "DK"}, 
	{"name": "Djibouti", "code": "DJ"}, 
	{"name": "Dominica", "code": "DM"}, 
	{"name": "Dominican Republic", "code": "DO"}, 
	{"name": "Ecuador", "code": "EC"}, 
	{"name": "Egypt", "code": "EG"}, 
	{"name": "El Salvador", "code": "SV"}, 
	{"name": "Equatorial Guinea", "code": "GQ"}, 
	{"name": "Eritrea", "code": "ER"}, 
	{"name": "Estonia", "code": "EE"}, 
	{"name": "Ethiopia", "code": "ET"}, 
	{"name": "Falkland Islands (Malvinas)", "code": "FK"}, 
	{"name": "Faroe Islands", "code": "FO"}, 
	{"name": "Fiji", "code": "FJ"}, 
	{"name": "Finland", "code": "FI"}, 
	{"name": "France", "code": "FR"}, 
	{"name": "French Guiana", "code": "GF"}, 
	{"name": "French Polynesia", "code": "PF"}, 
	{"name": "French Southern Territories", "code": "TF"}, 
	{"name": "Gabon", "code": "GA"}, 
	{"name": "Gambia", "code": "GM"}, 
	{"name": "Georgia", "code": "GE"}, 
	{"name": "Germany", "code": "DE"}, 
	{"name": "Ghana", "code": "GH"}, 
	{"name": "Gibraltar", "code": "GI"}, 
	{"name": "Greece", "code": "GR"}, 
	{"name": "Greenland", "code": "GL"}, 
	{"name": "Grenada", "code": "GD"}, 
	{"name": "Guadeloupe", "code": "GP"}, 
	{"name": "Guam", "code": "GU"}, 
	{"name": "Guatemala", "code": "GT"}, 
	{"name": "Guernsey", "code": "GG"}, 
	{"name": "Guinea", "code": "GN"}, 
	{"name": "Guinea-Bissau", "code": "GW"}, 
	{"name": "Guyana", "code": "GY"}, 
	{"name": "Haiti", "code": "HT"}, 
	{"name": "Heard Island and Mcdonald Islands", "code": "HM"}, 
	{"name": "Holy See (Vatican City State)", "code": "VA"}, 
	{"name": "Honduras", "code": "HN"}, 
	{"name": "Hong Kong", "code": "HK"}, 
	{"name": "Hungary", "code": "HU"}, 
	{"name": "Iceland", "code": "IS"}, 
	{"name": "India", "code": "IN"}, 
	{"name": "Indonesia", "code": "ID"}, 
	{"name": "Iran, Islamic Republic Of", "code": "IR"}, 
	{"name": "Iraq", "code": "IQ"}, 
	{"name": "Ireland", "code": "IE"}, 
	{"name": "Isle of Man", "code": "IM"}, 
	{"name": "Israel", "code": "IL"}, 
	{"name": "Italy", "code": "IT"}, 
	{"name": "Jamaica", "code": "JM"}, 
	{"name": "Japan", "code": "JP"}, 
	{"name": "Jersey", "code": "JE"}, 
	{"name": "Jordan", "code": "JO"}, 
	{"name": "Kazakhstan", "code": "KZ"}, 
	{"name": "Kenya", "code": "KE"}, 
	{"name": "Kiribati", "code": "KI"}, 
	{"name": "Korea, Democratic People'S Republic of", "code": "KP"}, 
	{"name": "Korea, Republic of", "code": "KR"}, 
	{"name": "Kuwait", "code": "KW"}, 
	{"name": "Kyrgyzstan", "code": "KG"}, 
	{"name": "Lao People'S Democratic Republic", "code": "LA"}, 
	{"name": "Latvia", "code": "LV"}, 
	{"name": "Lebanon", "code": "LB"}, 
	{"name": "Lesotho", "code": "LS"}, 
	{"name": "Liberia", "code": "LR"}, 
	{"name": "Libyan Arab Jamahiriya", "code": "LY"}, 
	{"name": "Liechtenstein", "code": "LI"}, 
	{"name": "Lithuania", "code": "LT"}, 
	{"name": "Luxembourg", "code": "LU"}, 
	{"name": "Macao", "code": "MO"}, 
	{"name": "Macedonia, The Former Yugoslav Republic of", "code": "MK"}, 
	{"name": "Madagascar", "code": "MG"}, 
	{"name": "Malawi", "code": "MW"}, 
	{"name": "Malaysia", "code": "MY"}, 
	{"name": "Maldives", "code": "MV"}, 
	{"name": "Mali", "code": "ML"}, 
	{"name": "Malta", "code": "MT"}, 
	{"name": "Marshall Islands", "code": "MH"}, 
	{"name": "Martinique", "code": "MQ"}, 
	{"name": "Mauritania", "code": "MR"}, 
	{"name": "Mauritius", "code": "MU"}, 
	{"name": "Mayotte", "code": "YT"}, 
	{"name": "Mexico", "code": "MX"}, 
	{"name": "Micronesia, Federated States of", "code": "FM"}, 
	{"name": "Moldova, Republic of", "code": "MD"}, 
	{"name": "Monaco", "code": "MC"}, 
	{"name": "Mongolia", "code": "MN"}, 
	{"name": "Montenegro", "code": "ME"},
	{"name": "Montserrat", "code": "MS"},
	{"name": "Morocco", "code": "MA"}, 
	{"name": "Mozambique", "code": "MZ"}, 
	{"name": "Myanmar", "code": "MM"}, 
	{"name": "Namibia", "code": "NA"}, 
	{"name": "Nauru", "code": "NR"}, 
	{"name": "Nepal", "code": "NP"}, 
	{"name": "Netherlands", "code": "NL"}, 
	{"name": "Netherlands Antilles", "code": "AN"}, 
	{"name": "New Caledonia", "code": "NC"}, 
	{"name": "New Zealand", "code": "NZ"}, 
	{"name": "Nicaragua", "code": "NI"}, 
	{"name": "Niger", "code": "NE"}, 
	{"name": "Nigeria", "code": "NG"}, 
	{"name": "Niue", "code": "NU"}, 
	{"name": "Norfolk Island", "code": "NF"}, 
	{"name": "Northern Mariana Islands", "code": "MP"}, 
	{"name": "Norway", "code": "NO"}, 
	{"name": "Oman", "code": "OM"}, 
	{"name": "Pakistan", "code": "PK"}, 
	{"name": "Palau", "code": "PW"}, 
	{"name": "Palestinian Territory, Occupied", "code": "PS"}, 
	{"name": "Panama", "code": "PA"}, 
	{"name": "Papua New Guinea", "code": "PG"}, 
	{"name": "Paraguay", "code": "PY"}, 
	{"name": "Peru", "code": "PE"}, 
	{"name": "Philippines", "code": "PH"}, 
	{"name": "Pitcairn", "code": "PN"}, 
	{"name": "Poland", "code": "PL"}, 
	{"name": "Portugal", "code": "PT"}, 
	{"name": "Puerto Rico", "code": "PR"}, 
	{"name": "Qatar", "code": "QA"}, 
	{"name": "Reunion", "code": "RE"}, 
	{"name": "Romania", "code": "RO"}, 
	{"name": "Russian Federation", "code": "RU"}, 
	{"name": "RWANDA", "code": "RW"}, 
	{"name": "Saint Helena", "code": "SH"}, 
	{"name": "Saint Kitts and Nevis", "code": "KN"}, 
	{"name": "Saint Lucia", "code": "LC"}, 
	{"name": "Saint Pierre and Miquelon", "code": "PM"}, 
	{"name": "Saint Vincent and the Grenadines", "code": "VC"}, 
	{"name": "Samoa", "code": "WS"}, 
	{"name": "San Marino", "code": "SM"}, 
	{"name": "Sao Tome and Principe", "code": "ST"}, 
	{"name": "Saudi Arabia", "code": "SA"}, 
	{"name": "Senegal", "code": "SN"}, 
	{"name": "Serbia", "code": "RS"}, 
	{"name": "Seychelles", "code": "SC"}, 
	{"name": "Sierra Leone", "code": "SL"}, 
	{"name": "Singapore", "code": "SG"}, 
	{"name": "Slovakia", "code": "SK"}, 
	{"name": "Slovenia", "code": "SI"}, 
	{"name": "Solomon Islands", "code": "SB"}, 
	{"name": "Somalia", "code": "SO"}, 
	{"name": "South Africa", "code": "ZA"}, 
	{"name": "South Georgia and the South Sandwich Islands", "code": "GS"}, 
	{"name": "Spain", "code": "ES"}, 
	{"name": "Sri Lanka", "code": "LK"}, 
	{"name": "Sudan", "code": "SD"}, 
	{"name": "Suriname", "code": "SR"}, 
	{"name": "Svalbard and Jan Mayen", "code": "SJ"}, 
	{"name": "Swaziland", "code": "SZ"}, 
	{"name": "Sweden", "code": "SE"}, 
	{"name": "Switzerland", "code": "CH"}, 
	{"name": "Syrian Arab Republic", "code": "SY"}, 
	{"name": "Taiwan, Province of China", "code": "TW"}, 
	{"name": "Tajikistan", "code": "TJ"}, 
	{"name": "Tanzania, United Republic of", "code": "TZ"}, 
	{"name": "Thailand", "code": "TH"}, 
	{"name": "Timor-Leste", "code": "TL"}, 
	{"name": "Togo", "code": "TG"}, 
	{"name": "Tokelau", "code": "TK"}, 
	{"name": "Tonga", "code": "TO"}, 
	{"name": "Trinidad and Tobago", "code": "TT"}, 
	{"name": "Tunisia", "code": "TN"}, 
	{"name": "Turkey", "code": "TR"}, 
	{"name": "Turkmenistan", "code": "TM"}, 
	{"name": "Turks and Caicos Islands", "code": "TC"}, 
	{"name": "Tuvalu", "code": "TV"}, 
	{"name": "Uganda", "code": "UG"}, 
	{"name": "Ukraine", "code": "UA"}, 
	{"name": "United Arab Emirates", "code": "AE"}, 
	{"name": "United Kingdom", "code": "GB"}, 
	{"name": "United States", "code": "US"}, 
	{"name": "United States Minor Outlying Islands", "code": "UM"}, 
	{"name": "Uruguay", "code": "UY"}, 
	{"name": "Uzbekistan", "code": "UZ"}, 
	{"name": "Vanuatu", "code": "VU"}, 
	{"name": "Venezuela", "code": "VE"}, 
	{"name": "Viet Nam", "code": "VN"}, 
	{"name": "Virgin Islands, British", "code": "VG"}, 
	{"name": "Virgin Islands, U.S.", "code": "VI"}, 
	{"name": "Wallis and Futuna", "code": "WF"}, 
	{"name": "Western Sahara", "code": "EH"}, 
	{"name": "Yemen", "code": "YE"}, 
	{"name": "Zambia", "code": "ZM"}, 
	{"name": "Zimbabwe", "code": "ZW"} 
];

export const citiesArray = [
	{
	"name": "Adak",
	"country": "United States"
	},
	{
	"name": "Apia",
	"country": "Samoa"
	},
	{
	"name": "Pago Pago",
	"country": "United States"
	},
	{
	"name": "Alofi",
	"country": "Niue"
	},
	{
	"name": "Avarua",
	"country": "Cook Islands"
	},
	{
	"name": "Honolulu",
	"country": "United States"
	},
	{
	"name": "Hilo",
	"country": "United States"
	},
	{
	"name": "Anchorage",
	"country": "United States"
	},
	{
	"name": "Papeete",
	"country": "French Polynesia"
	},
	{
	"name": "Fairbanks",
	"country": "United States"
	},
	{
	"name": "Sitka",
	"country": "United States"
	},
	{
	"name": "Whitehorse",
	"country": "Canada"
	},
	{
	"name": "Juneau",
	"country": "United States"
	},
	{
	"name": "Adamstown",
	"country": "United States"
	},
	{
	"name": "Vancouver",
	"country": "Canada"
	},
	{
	"name": "Portland",
	"country": "United States"
	},
	{
	"name": "San Francisco",
	"country": "United States"
	},
	{
	"name": "Seattle",
	"country": "United States"
	},
	{
	"name": "Sacramento",
	"country": "United States"
	},
	{
	"name": "Los Angeles",
	"country": "United States"
	},
	{
	"name": "Riverside",
	"country": "United States"
	},
	{
	"name": "San Diego",
	"country": "United States"
	},
	{
	"name": "Tijuana",
	"country": "Mexico"
	},
	{
	"name": "Mexicali",
	"country": "Mexico"
	},
	{
	"name": "Las Vegas",
	"country": "United States"
	},
	{
	"name": "Yellowknife",
	"country": "Canada"
	},
	{
	"name": "Calgary",
	"country": "Canada"
	},
	{
	"name": "Edmonton",
	"country": "Canada"
	},
	{
	"name": "Phoenix",
	"country": "United States"
	},
	{
	"name": "Salt Lake City",
	"country": "United States"
	},
	{
	"name": "Tucson",
	"country": "United States"
	},
	{
	"name": "Hanga Roa",
	"country": "Chile"
	},
	{
	"name": "Saskatoon",
	"country": "Canada"
	},
	{
	"name": "Albuquerque",
	"country": "United States"
	},
	{
	"name": "El Paso",
	"country": "United States"
	},
	{
	"name": "Chihuahua",
	"country": "Mexico"
	},
	{
	"name": "Denver",
	"country": "United States"
	},
	{
	"name": "Durango",
	"country": "Mexico"
	},
	{
	"name": "Regina",
	"country": "Canada"
	},
	{
	"name": "Zapopan",
	"country": "Mexico"
	},
	{
	"name": "Guadalajara",
	"country": "Mexico"
	},
	{
	"name": "Monterrey",
	"country": "Mexico"
	},
	{
	"name": "Mexico City",
	"country": "Mexico"
	},
	{
	"name": "San Antonio",
	"country": "United States"
	},
	{
	"name": "Puebla",
	"country": "Mexico"
	},
	{
	"name": "Austin",
	"country": "United States"
	},
	{
	"name": "Oklahoma City",
	"country": "United States"
	},
	{
	"name": "Wichita",
	"country": "United States"
	},
	{
	"name": "Winnipeg",
	"country": "Canada"
	},
	{
	"name": "Dallas",
	"country": "United States"
	},
	{
	"name": "Veracruz",
	"country": "Mexico"
	},
	{
	"name": "Houston",
	"country": "United States"
	},
	{
	"name": "Kansas City",
	"country": "United States"
	},
	{
	"name": "Minneapolis",
	"country": "United States"
	},
	{
	"name": "Quetzaltenango",
	"country": "Guatemala"
	},
	{
	"name": "Guatemala City",
	"country": "Guatemala"
	},
	{
	"name": "New Orleans",
	"country": "United States"
	},
	{
	"name": "Memphis",
	"country": "United States"
	},
	{
	"name": "Mérida",
	"country": "Mexico"
	},
	{
	"name": "San Salvador",
	"country": "El Salvador"
	},
	{
	"name": "Belmopan",
	"country": "Belize"
	},
	{
	"name": "Belize City",
	"country": "Belize"
	},
	{
	"name": "Milwaukee",
	"country": "United States"
	},
	{
	"name": "Chicago",
	"country": "United States"
	},
	{
	"name": "Tegucigalpa",
	"country": "Honduras"
	},
	{
	"name": "Nashville",
	"country": "United States"
	},
	{
	"name": "Managua",
	"country": "Nicaragua"
	},
	{
	"name": "Indianapolis",
	"country": "United States"
	},
	{
	"name": "Louisville",
	"country": "United States"
	},
	{
	"name": "Cincinnati",
	"country": "United States"
	},
	{
	"name": "Atlanta",
	"country": "United States"
	},
	{
	"name": "San José",
	"country": "Costa Rica"
	},
	{
	"name": "Detroit",
	"country": "United States"
	},
	{
	"name": "Columbus",
	"country": "United States"
	},
	{
	"name": "Tampa",
	"country": "United States"
	},
	{
	"name": "Havana",
	"country": "Cuba"
	},
	{
	"name": "Cleveland",
	"country": "United States"
	},
	{
	"name": "Jacksonville",
	"country": "United States"
	},
	{
	"name": "George Town",
	"country": "Malaysia"
	},
	{
	"name": "Charlotte",
	"country": "United States"
	},
	{
	"name": "Miami",
	"country": "United States"
	},
	{
	"name": "Pittsburgh",
	"country": "United States"
	},
	{
	"name": "Guayaquil",
	"country": "Ecuador"
	},
	{
	"name": "Panama City",
	"country": "Panama"
	},
	{
	"name": "Toronto",
	"country": "Canada"
	},
	{
	"name": "Buffalo",
	"country": "United States"
	},
	{
	"name": "Raleigh",
	"country": "United States"
	},
	{
	"name": "Quito",
	"country": "Ecuador"
	},
	{
	"name": "Rochester",
	"country": "United States"
	},
	{
	"name": "Nassau",
	"country": "The Bahamas"
	},
	{
	"name": "Washington",
	"country": "United States"
	},
	{
	"name": "Lima",
	"country": "Peru"
	},
	{
	"name": "Kingston",
	"country": "Jamaica"
	},
	{
	"name": "Baltimore",
	"country": "United States"
	},
	{
	"name": "Cali",
	"country": "Colombia"
	},
	{
	"name": "Virginia Beach",
	"country": "United States"
	},
	{
	"name": "Ottawa",
	"country": "Canada"
	},
	{
	"name": "Medellín",
	"country": "Colombia"
	},
	{
	"name": "Cartagena",
	"country": "Colombia"
	},
	{
	"name": "Philadelphia",
	"country": "United States"
	},
	{
	"name": "Barranquilla",
	"country": "Colombia"
	},
	{
	"name": "Forked River",
	"country": "United States"
	},
	{
	"name": "Jersey City",
	"country": "United States"
	},
	{
	"name": "Bogotá",
	"country": "Colombia"
	},
	{
	"name": "Montreal",
	"country": "Canada"
	},
	{
	"name": "Iquitos",
	"country": "Peru"
	},
	{
	"name": "Valdivia",
	"country": "Chile"
	},
	{
	"name": "Concepción",
	"country": "Chile"
	},
	{
	"name": "Cusco",
	"country": "Peru"
	},
	{
	"name": "Maracaibo",
	"country": "Venezuela"
	},
	{
	"name": "Valparaíso",
	"country": "Chile"
	},
	{
	"name": "Arequipa",
	"country": "Peru"
	},
	{
	"name": "Providence",
	"country": "United States"
	},
	{
	"name": "La Serena",
	"country": "Chile"
	},
	{
	"name": "Cockburn Town",
	"country": "Turks and Caicos Islands"
	},
	{
	"name": "Boston",
	"country": "United States"
	},
	{
	"name": "Punta Arenas",
	"country": "Chile"
	},
	{
	"name": "Santiago",
	"country": "Chile"
	},
	{
	"name": "Antofagasta",
	"country": "Chile"
	},
	{
	"name": "Iquique",
	"country": "Chile"
	},
	{
	"name": "Oranjestad",
	"country": "Aruba"
	},
	{
	"name": "Santo Domingo",
	"country": "Dominican Republic"
	},
	{
	"name": "Rio Branco",
	"country": "Brazil"
	},
	{
	"name": "Willemstad",
	"country": "Netherlands Antilles"
	},
	{
	"name": "Calama",
	"country": "Chile"
	},
	{
	"name": "Mendoza",
	"country": "Argentina"
	},
	{
	"name": "Iqaluit",
	"country": "Canada"
	},
	{
	"name": "Ushuaia",
	"country": "Argentina"
	},
	{
	"name": "La Paz",
	"country": "Bolivia"
	},
	{
	"name": "Puerto Williams",
	"country": "Chile"
	},
	{
	"name": "Caracas",
	"country": "Venezuela"
	},
	{
	"name": "Fredericton",
	"country": "Canada"
	},
	{
	"name": "San Juan",
	"country": "Puerto Rico"
	},
	{
	"name": "Saint John",
	"country": "Grenada"
	},
	{
	"name": "Sucre",
	"country": "Bolivia"
	},
	{
	"name": "Charlotte Amalie",
	"country": "United States"
	},
	{
	"name": "Hamilton",
	"country": "Bermuda"
	},
	{
	"name": "Road Town",
	"country": "British Virgin Islands"
	},
	{
	"name": "Córdoba",
	"country": "Argentina"
	},
	{
	"name": "Porto Velho",
	"country": "Brazil"
	},
	{
	"name": "Charlottetown",
	"country": "Canada"
	},
	{
	"name": "The Valley",
	"country": "Anguilla"
	},
	{
	"name": "Basse-Terre",
	"country": "Guadeloupe"
	},
	{
	"name": "Bahía Blanca",
	"country": "Argentina"
	},
	{
	"name": "St. John's",
	"country": "Canada"
	},
	{
	"name": "San Fernando",
	"country": "Trinidad and Tobago"
	},
	{
	"name": "Roseau",
	"country": "Dominica"
	},
	{
	"name": "Chaguanas",
	"country": "Trinidad and Tobago"
	},
	{
	"name": "Kingstown",
	"country": "Saint Vincent and the Grenadines"
	},
	{
	"name": "Castries",
	"country": "St. Lucia"
	},
	{
	"name": "Rosario",
	"country": "Argentina"
	},
	{
	"name": "Boa Vista",
	"country": "Brazil"
	},
	{
	"name": "Manaus",
	"country": "Brazil"
	},
	{
	"name": "Bridgetown",
	"country": "Barbados"
	},
	{
	"name": "Buenos Aires",
	"country": "Argentina"
	},
	{
	"name": "Stanley",
	"country": "Hong Kong"
	},
	{
	"name": "Asunción",
	"country": "Paraguay"
	},
	{
	"name": "Saint-Pierre",
	"country": "Réunion"
	},
	{
	"name": "Montevideo",
	"country": "Uruguay"
	},
	{
	"name": "Cuiabá",
	"country": "Brazil"
	},
	{
	"name": "Paramaribo",
	"country": "Suriname"
	},
	{
	"name": "Campo Grande",
	"country": "Brazil"
	},
	{
	"name": "Chuí",
	"country": "Brazil"
	},
	{
	"name": "Pelotas",
	"country": "Brazil"
	},
	{
	"name": "Cayenne",
	"country": "French Guiana"
	},
	{
	"name": "Nuuk",
	"country": "Greenland"
	},
	{
	"name": "Porto Alegre",
	"country": "Brazil"
	},
	{
	"name": "Macapá",
	"country": "Brazil"
	},
	{
	"name": "Assis",
	"country": "Italy"
	},
	{
	"name": "Curitiba",
	"country": "Brazil"
	},
	{
	"name": "Belém",
	"country": "Brazil"
	},
	{
	"name": "Brasília",
	"country": "Brazil"
	},
	{
	"name": "Campinas",
	"country": "Brazil"
	},
	{
	"name": "São Paulo",
	"country": "Brazil"
	},
	{
	"name": "Vitória",
	"country": "Brazil"
	},
	{
	"name": "Ilhéus",
	"country": "Brazil"
	},
	{
	"name": "Fortaleza",
	"country": "Brazil"
	},
	{
	"name": "Maceió",
	"country": "Brazil"
	},
	{
	"name": "Recife",
	"country": "Brazil"
	},
	{
	"name": "Ponta Delgada",
	"country": "Portugal"
	},
	{
	"name": "Praia",
	"country": "Brazil"
	},
	{
	"name": "Reykjavík",
	"country": "Iceland"
	},
	{
	"name": "Dakar",
	"country": "Senegal"
	},
	{
	"name": "Thiès",
	"country": "Senegal"
	},
	{
	"name": "Serekunda",
	"country": "The Gambia"
	},
	{
	"name": "Brikama",
	"country": "The Gambia"
	},
	{
	"name": "Banjul",
	"country": "The Gambia"
	},
	{
	"name": "Nouakchott",
	"country": "Mauritania"
	},
	{
	"name": "Bissau",
	"country": "Guinea-Bissau"
	},
	{
	"name": "Conakry",
	"country": "Guinea"
	},
	{
	"name": "Freetown",
	"country": "Sierra Leone"
	},
	{
	"name": "El Aaiún",
	"country": "Western Sahara"
	},
	{
	"name": "Monrovia",
	"country": "Liberia"
	},
	{
	"name": "Lisbon",
	"country": "Portugal"
	},
	{
	"name": "Porto",
	"country": "Brazil"
	},
	{
	"name": "Bamako",
	"country": "Mali"
	},
	{
	"name": "Casablanca",
	"country": "Morocco"
	},
	{
	"name": "Koulikoro",
	"country": "Mali"
	},
	{
	"name": "Rabat",
	"country": "Morocco"
	},
	{
	"name": "Tórshavn",
	"country": "Faroe Islands"
	},
	{
	"name": "Dublin",
	"country": "Ireland"
	},
	{
	"name": "Seville",
	"country": "Spain"
	},
	{
	"name": "Belfast",
	"country": "United Kingdom"
	},
	{
	"name": "Jamestown",
	"country": "United States"
	},
	{
	"name": "Gibraltar",
	"country": "Gibraltar"
	},
	{
	"name": "Yamoussoukro",
	"country": "Côte d'Ivoire"
	},
	{
	"name": "Douglas",
	"country": "Australia"
	},
	{
	"name": "Málaga",
	"country": "Spain"
	},
	{
	"name": "Glasgow",
	"country": "United Kingdom"
	},
	{
	"name": "Abidjan",
	"country": "Côte d'Ivoire"
	},
	{
	"name": "Madrid",
	"country": "Spain"
	},
	{
	"name": "Cardiff",
	"country": "United Kingdom"
	},
	{
	"name": "Edinburgh",
	"country": "United Kingdom"
	},
	{
	"name": "Timbuktu",
	"country": "Mali"
	},
	{
	"name": "Liverpool",
	"country": "United Kingdom"
	},
	{
	"name": "Bilbao",
	"country": "Spain"
	},
	{
	"name": "Manchester",
	"country": "United Kingdom"
	},
	{
	"name": "Aberdeen",
	"country": "United Kingdom"
	},
	{
	"name": "Birmingham",
	"country": "United Kingdom"
	},
	{
	"name": "Leeds",
	"country": "United Kingdom"
	},
	{
	"name": "Nantes",
	"country": "France"
	},
	{
	"name": "Ouagadougou",
	"country": "Burkina Faso"
	},
	{
	"name": "Tamale",
	"country": "Ghana"
	},
	{
	"name": "Valencia",
	"country": "Spain"
	},
	{
	"name": "Accra",
	"country": "Ghana"
	},
	{
	"name": "London",
	"country": "United Kingdom"
	},
	{
	"name": "Greenwich",
	"country": "United Kingdom"
	},
	{
	"name": "Lomé",
	"country": "Togo"
	},
	{
	"name": "Toulouse",
	"country": "France"
	},
	{
	"name": "Ibiza",
	"country": "Spain"
	},
	{
	"name": "Niamey",
	"country": "Niger"
	},
	{
	"name": "Barcelona",
	"country": "Spain"
	},
	{
	"name": "Paris",
	"country": "France"
	},
	{
	"name": "Cotonou",
	"country": "Benin"
	},
	{
	"name": "Porto-Novo",
	"country": "Benin"
	},
	{
	"name": "Palma",
	"country": "Spain"
	},
	{
	"name": "Algiers",
	"country": "Algeria"
	},
	{
	"name": "Lagos",
	"country": "Nigeria"
	},
	{
	"name": "Ibadan",
	"country": "Nigeria"
	},
	{
	"name": "The Hague",
	"country": "Netherlands"
	},
	{
	"name": "Brussels",
	"country": "Belgium"
	},
	{
	"name": "Antwerp",
	"country": "Belgium"
	},
	{
	"name": "Rotterdam",
	"country": "Netherlands"
	},
	{
	"name": "Lyon",
	"country": "France"
	},
	{
	"name": "Amsterdam",
	"country": "Netherlands"
	},
	{
	"name": "Marseille",
	"country": "France"
	},
	{
	"name": "Bergen",
	"country": "Norway"
	},
	{
	"name": "Geneva",
	"country": "Switzerland"
	},
	{
	"name": "São Tomé",
	"country": "Brazil"
	},
	{
	"name": "Düsseldorf",
	"country": "Germany"
	},
	{
	"name": "Cologne",
	"country": "Germany"
	},
	{
	"name": "Cannes",
	"country": "France"
	},
	{
	"name": "Nice",
	"country": "France"
	},
	{
	"name": "Monaco",
	"country": "Monaco"
	},
	{
	"name": "Bern",
	"country": "Brazil"
	},
	{
	"name": "Abuja",
	"country": "Nigeria"
	},
	{
	"name": "Enugu",
	"country": "Nigeria"
	},
	{
	"name": "Turin",
	"country": "Italy"
	},
	{
	"name": "Strasbourg",
	"country": "France"
	},
	{
	"name": "Kano",
	"country": "Nigeria"
	},
	{
	"name": "Zürich",
	"country": "Switzerland"
	},
	{
	"name": "Frankfurt",
	"country": "Germany"
	},
	{
	"name": "Malabo",
	"country": "Philippines"
	},
	{
	"name": "Stuttgart",
	"country": "Germany"
	},
	{
	"name": "Milan",
	"country": "Italy"
	},
	{
	"name": "Libreville",
	"country": "Gabon"
	},
	{
	"name": "Vaduz",
	"country": "Liechtenstein"
	},
	{
	"name": "Douala",
	"country": "Cameroon"
	},
	{
	"name": "Hanover",
	"country": "Germany"
	},
	{
	"name": "Hamburg",
	"country": "Germany"
	},
	{
	"name": "Tunis",
	"country": "Tunisia"
	},
	{
	"name": "Aarhus",
	"country": "Denmark"
	},
	{
	"name": "Oslo",
	"country": "Norway"
	},
	{
	"name": "Innsbruck",
	"country": "Austria"
	},
	{
	"name": "Yaoundé",
	"country": "Cameroon"
	},
	{
	"name": "Munich",
	"country": "Germany"
	},
	{
	"name": "Gothenburg",
	"country": "Sweden"
	},
	{
	"name": "Leipzig",
	"country": "Germany"
	},
	{
	"name": "Rome",
	"country": "Italy"
	},
	{
	"name": "Copenhagen",
	"country": "Denmark"
	},
	{
	"name": "Malmö",
	"country": "Sweden"
	},
	{
	"name": "Salzburg",
	"country": "Austria"
	},
	{
	"name": "Tripoli",
	"country": "Libya"
	},
	{
	"name": "Luanda",
	"country": "Angola"
	},
	{
	"name": "Berlin",
	"country": "Germany"
	},
	{
	"name": "Dresden",
	"country": "Germany"
	},
	{
	"name": "Naples",
	"country": "Italy"
	},
	{
	"name": "Linz",
	"country": "Austria"
	},
	{
	"name": "Prague",
	"country": "Czech Republic"
	},
	{
	"name": "Sabha",
	"country": "Libya"
	},
	{
	"name": "Birkirkara",
	"country": "Malta"
	},
	{
	"name": "Ljubljana",
	"country": "Slovenia"
	},
	{
	"name": "Valletta",
	"country": "Malta"
	},
	{
	"name": "N'Djamena",
	"country": "Chad"
	},
	{
	"name": "Brazzaville",
	"country": "Republic of the Congo"
	},
	{
	"name": "Kinshasa",
	"country": "Democratic Republic of the Congo"
	},
	{
	"name": "Graz",
	"country": "Austria"
	},
	{
	"name": "Longyearbyen",
	"country": "Svalbard and Jan Mayen"
	},
	{
	"name": "Zagreb",
	"country": "Croatia"
	},
	{
	"name": "Vienna",
	"country": "Austria"
	},
	{
	"name": "Bratislava",
	"country": "Slovakia"
	},
	{
	"name": "Stockholm",
	"country": "Sweden"
	},
	{
	"name": "Sarajevo",
	"country": "Bosnia and Herzegovina"
	},
	{
	"name": "Cape Town",
	"country": "South Africa"
	},
	{
	"name": "Bangui",
	"country": "Central African Republic"
	},
	{
	"name": "Budapest",
	"country": "Hungary"
	},
	{
	"name": "Podgorica",
	"country": "Montenegro"
	},
	{
	"name": "Tirana",
	"country": "Albania"
	},
	{
	"name": "Kraków",
	"country": "Poland"
	},
	{
	"name": "Belgrade",
	"country": "Serbia"
	},
	{
	"name": "Kaliningrad",
	"country": "Russia"
	},
	{
	"name": "Warsaw",
	"country": "Poland"
	},
	{
	"name": "Pristina",
	"country": "Kosovo"
	},
	{
	"name": "Skopje",
	"country": "Macedonia"
	},
	{
	"name": "Sofia",
	"country": "Bulgaria"
	},
	{
	"name": "Athens",
	"country": "Greece"
	},
	{
	"name": "Tampere",
	"country": "Finland"
	},
	{
	"name": "Lviv",
	"country": "Ukraine"
	},
	{
	"name": "Riga",
	"country": "Latvia"
	},
	{
	"name": "Espoo",
	"country": "Finland"
	},
	{
	"name": "Tallinn",
	"country": "Estonia"
	},
	{
	"name": "Helsinki",
	"country": "Finland"
	},
	{
	"name": "Vilnius",
	"country": "Lithuania"
	},
	{
	"name": "Port Elizabeth",
	"country": "South Africa"
	},
	{
	"name": "Livingstone",
	"country": "Zambia"
	},
	{
	"name": "Gaborone",
	"country": "Botswana"
	},
	{
	"name": "Bucharest",
	"country": "Romania"
	},
	{
	"name": "Bloemfontein",
	"country": "South Africa"
	},
	{
	"name": "Tartu",
	"country": "Syria"
	},
	{
	"name": "Lubumbashi",
	"country": "Democratic Republic of the Congo"
	},
	{
	"name": "Maseru",
	"country": "Lesotho"
	},
	{
	"name": "Francistown",
	"country": "Botswana"
	},
	{
	"name": "Minsk",
	"country": "Belarus"
	},
	{
	"name": "Johannesburg",
	"country": "South Africa"
	},
	{
	"name": "Pretoria",
	"country": "South Africa"
	},
	{
	"name": "Lusaka",
	"country": "Zambia"
	},
	{
	"name": "Ndola",
	"country": "Zambia"
	},
	{
	"name": "Bulawayo",
	"country": "Zimbabwe"
	},
	{
	"name": "Istanbul",
	"country": "Turkey"
	},
	{
	"name": "Bursa",
	"country": "Turkey"
	},
	{
	"name": "Bujumbura",
	"country": "Burundi"
	},
	{
	"name": "Tiraspol",
	"country": "Moldova"
	},
	{
	"name": "Alexandria",
	"country": "Egypt"
	},
	{
	"name": "Kigali",
	"country": "Rwanda"
	},
	{
	"name": "Saint Petersburg",
	"country": "Russia"
	},
	{
	"name": "Odessa",
	"country": "Ukraine"
	},
	{
	"name": "Harare",
	"country": "Zimbabwe"
	},
	{
	"name": "Durban",
	"country": "South Africa"
	},
	{
	"name": "Mbabane",
	"country": "Swaziland"
	},
	{
	"name": "Lobamba",
	"country": "Swaziland"
	},
	{
	"name": "Cairo",
	"country": "Egypt"
	},
	{
	"name": "Manzini",
	"country": "Swaziland"
	},
	{
	"name": "Port Said",
	"country": "Egypt"
	},
	{
	"name": "Konya",
	"country": "Turkey"
	},
	{
	"name": "Omdurman",
	"country": "Sudan"
	},
	{
	"name": "Khartoum",
	"country": "Sudan"
	},
	{
	"name": "Suez",
	"country": "Egypt"
	},
	{
	"name": "Maputo",
	"country": "Mozambique"
	},
	{
	"name": "Luxor",
	"country": "Egypt"
	},
	{
	"name": "Ankara",
	"country": "Turkey"
	},
	{
	"name": "Mwanza",
	"country": "Tanzania"
	},
	{
	"name": "Murmansk",
	"country": "Russia"
	},
	{
	"name": "Nicosia",
	"country": "Cyprus"
	},
	{
	"name": "Lilongwe",
	"country": "Malawi"
	},
	{
	"name": "Simferopol",
	"country": "Ukraine"
	},
	{
	"name": "Gaza",
	"country": "Palestine"
	},
	{
	"name": "Mersin",
	"country": "Turkey"
	},
	{
	"name": "Tel Aviv",
	"country": "Israel"
	},
	{
	"name": "Blantyre",
	"country": "Malawi"
	},
	{
	"name": "Jerusalem",
	"country": "Israel"
	},
	{
	"name": "Adana",
	"country": "Turkey"
	},
	{
	"name": "Beirut",
	"country": "Lebanon"
	},
	{
	"name": "Dodoma",
	"country": "Tanzania"
	},
	{
	"name": "Amman",
	"country": "Jordan"
	},
	{
	"name": "Damascus",
	"country": "Syria"
	},
	{
	"name": "Kharkiv",
	"country": "Ukraine"
	},
	{
	"name": "Nairobi",
	"country": "Kenya"
	},
	{
	"name": "Gaziantep",
	"country": "Turkey"
	},
	{
	"name": "Moscow",
	"country": "Russia"
	},
	{
	"name": "Addis Ababa",
	"country": "Ethiopia"
	},
	{
	"name": "Asmara",
	"country": "Eritrea"
	},
	{
	"name": "Jeddah",
	"country": "Saudi Arabia"
	},
	{
	"name": "Zanzibar City",
	"country": "Tanzania"
	},
	{
	"name": "Medina",
	"country": "Saudi Arabia"
	},
	{
	"name": "Mecca",
	"country": "Saudi Arabia"
	},
	{
	"name": "Sukhumi",
	"country": "Georgia"
	},
	{
	"name": "Moroni",
	"country": "Comoros"
	},
	{
	"name": "Baghdad",
	"country": "Iraq"
	},
	{
	"name": "Hargeisa",
	"country": "Somalia"
	},
	{
	"name": "Yerevan",
	"country": "Armenia"
	},
	{
	"name": "Tbilisi",
	"country": "Georgia"
	},
	{
	"name": "Mamoudzou",
	"country": "Mayotte"
	},
	{
	"name": "Mogadishu",
	"country": "Somalia"
	},
	{
	"name": "Tabriz",
	"country": "Iran"
	},
	{
	"name": "Riyadh",
	"country": "Saudi Arabia"
	},
	{
	"name": "Stepanakert",
	"country": "Azerbaijan"
	},
	{
	"name": "Antananarivo",
	"country": "Madagascar"
	},
	{
	"name": "Basra",
	"country": "Iraq"
	},
	{
	"name": "Baku",
	"country": "Azerbaijan"
	},
	{
	"name": "Dammam",
	"country": "Saudi Arabia"
	},
	{
	"name": "Samara",
	"country": "Russia"
	},
	{
	"name": "Manama",
	"country": "Bahrain"
	},
	{
	"name": "Tehran",
	"country": "Iran"
	},
	{
	"name": "Doha",
	"country": "Qatar"
	},
	{
	"name": "Abu Dhabi",
	"country": "United Arab Emirates"
	},
	{
	"name": "Dubai",
	"country": "United Arab Emirates"
	},
	{
	"name": "Saint-Denis",
	"country": "Réunion"
	},
	{
	"name": "Perm",
	"country": "Russia"
	},
	{
	"name": "Port Louis",
	"country": "Mauritius"
	},
	{
	"name": "Ashgabat",
	"country": "Turkmenistan"
	},
	{
	"name": "Muscat",
	"country": "Oman"
	},
	{
	"name": "Nukus",
	"country": "Uzbekistan"
	},
	{
	"name": "Mashhad",
	"country": "Iran"
	},
	{
	"name": "Yekaterinburg",
	"country": "Russia"
	},
	{
	"name": "Kandahar",
	"country": "Afghanistan"
	},
	{
	"name": "Karachi",
	"country": "Pakistan"
	},
	{
	"name": "Hyderabad",
	"country": "India"
	},
	{
	"name": "Dushanbe",
	"country": "Tajikistan"
	},
	{
	"name": "Kabul",
	"country": "Afghanistan"
	},
	{
	"name": "Tashkent",
	"country": "Uzbekistan"
	},
	{
	"name": "Astana",
	"country": "Kazakhstan"
	},
	{
	"name": "Multan",
	"country": "Pakistan"
	},
	{
	"name": "Peshawar",
	"country": "Pakistan"
	},
	{
	"name": "Namangan",
	"country": "Uzbekistan"
	},
	{
	"name": "Ahmedabad",
	"country": "India"
	},
	{
	"name": "Mumbai",
	"country": "India"
	},
	{
	"name": "Surat",
	"country": "India"
	},
	{
	"name": "Faisalabad",
	"country": "Pakistan"
	},
	{
	"name": "Rawalpindi",
	"country": "Pakistan"
	},
	{
	"name": "Islamabad",
	"country": "Pakistan"
	},
	{
	"name": "Omsk",
	"country": "Russia"
	},
	{
	"name": "Pune",
	"country": "India"
	},
	{
	"name": "Lahore",
	"country": "Pakistan"
	},
	{
	"name": "Bishkek",
	"country": "Kyrgyzstan"
	},
	{
	"name": "Srinagar",
	"country": "India"
	},
	{
	"name": "Amritsar",
	"country": "India"
	},
	{
	"name": "Jaipur",
	"country": "India"
	},
	{
	"name": "Ludhiana",
	"country": "India"
	},
	{
	"name": "Almaty",
	"country": "Kazakhstan"
	},
	{
	"name": "New Delhi",
	"country": "India"
	},
	{
	"name": "Bangalore",
	"country": "India"
	},
	{
	"name": "Nagpur",
	"country": "India"
	},
	{
	"name": "Colombo",
	"country": "Sri Lanka"
	},
	{
	"name": "Chennai",
	"country": "India"
	},
	{
	"name": "Kanpur",
	"country": "India"
	},
	{
	"name": "Kandy",
	"country": "Sri Lanka"
	},
	{
	"name": "Lucknow",
	"country": "India"
	},
	{
	"name": "Batticaloa",
	"country": "Sri Lanka"
	},
	{
	"name": "Novosibirsk",
	"country": "Russia"
	},
	{
	"name": "Patna",
	"country": "India"
	},
	{
	"name": "Kathmandu",
	"country": "Nepal"
	},
	{
	"name": "Ürümqi",
	"country": "China"
	},
	{
	"name": "Norilsk",
	"country": "Russia"
	},
	{
	"name": "Gangtok",
	"country": "India"
	},
	{
	"name": "Shigatse",
	"country": "China"
	},
	{
	"name": "Thimphu",
	"country": "Bhutan"
	},
	{
	"name": "Dhaka",
	"country": "Bangladesh"
	},
	{
	"name": "Lhasa",
	"country": "China"
	},
	{
	"name": "Agartala",
	"country": "India"
	},
	{
	"name": "Guwahati",
	"country": "India"
	},
	{
	"name": "Chittagong",
	"country": "Bangladesh"
	},
	{
	"name": "Shillong",
	"country": "India"
	},
	{
	"name": "Port Blair",
	"country": "India"
	},
	{
	"name": "Dibrugarh",
	"country": "India"
	},
	{
	"name": "Banda Aceh",
	"country": "Indonesia"
	},
	{
	"name": "Yangon",
	"country": "Myanmar"
	},
	{
	"name": "Medan",
	"country": "Indonesia"
	},
	{
	"name": "Chiang Mai",
	"country": "Thailand"
	},
	{
	"name": "Surat Thani",
	"country": "Thailand"
	},
	{
	"name": "Padang",
	"country": "Indonesia"
	},
	{
	"name": "Hat Yai",
	"country": "Thailand"
	},
	{
	"name": "Bangkok",
	"country": "Thailand"
	},
	{
	"name": "Pattaya",
	"country": "Thailand"
	},
	{
	"name": "Ipoh",
	"country": "Malaysia"
	},
	{
	"name": "Pekanbaru",
	"country": "Indonesia"
	},
	{
	"name": "Bratsk",
	"country": "Russia"
	},
	{
	"name": "Kuala Lumpur",
	"country": "Malaysia"
	},
	{
	"name": "Xining",
	"country": "China"
	},
	{
	"name": "Nakhon Ratchasima",
	"country": "Thailand"
	},
	{
	"name": "Kota Bharu",
	"country": "Malaysia"
	},
	{
	"name": "Vientiane",
	"country": "Laos"
	},
	{
	"name": "Kunming",
	"country": "China"
	},
	{
	"name": "Udon Thani",
	"country": "Thailand"
	},
	{
	"name": "Johor Bahru",
	"country": "Malaysia"
	},
	{
	"name": "Lanzhou",
	"country": "China"
	},
	{
	"name": "Singapore",
	"country": "Singapore"
	},
	{
	"name": "Siem Reap",
	"country": "Cambodia"
	},
	{
	"name": "Chengdu",
	"country": "China"
	},
	{
	"name": "Palembang",
	"country": "Indonesia"
	},
	{
	"name": "Phnom Penh",
	"country": "Cambodia"
	},
	{
	"name": "Hanoi",
	"country": "Vietnam"
	},
	{
	"name": "Chongqing",
	"country": "China"
	},
	{
	"name": "Hai Phong",
	"country": "Vietnam"
	},
	{
	"name": "Ho Chi Minh City",
	"country": "Vietnam"
	},
	{
	"name": "Jakarta",
	"country": "Indonesia"
	},
	{
	"name": "Bogor",
	"country": "Indonesia"
	},
	{
	"name": "Ulan Bator",
	"country": "Mongolia"
	},
	{
	"name": "Bandung",
	"country": "Indonesia"
	},
	{
	"name": "Da Nang",
	"country": "Vietnam"
	},
	{
	"name": "Nanning",
	"country": "China"
	},
	{
	"name": "Pontianak",
	"country": "Indonesia"
	},
	{
	"name": "Kuching",
	"country": "Malaysia"
	},
	{
	"name": "Semarang",
	"country": "Indonesia"
	},
	{
	"name": "Taiyuan",
	"country": "China"
	},
	{
	"name": "Malang",
	"country": "Indonesia"
	},
	{
	"name": "Surabaya",
	"country": "Indonesia"
	},
	{
	"name": "Guangzhou",
	"country": "China"
	},
	{
	"name": "Macau",
	"country": "Macau"
	},
	{
	"name": "Zhengzhou",
	"country": "China"
	},
	{
	"name": "Dongguan",
	"country": "China"
	},
	{
	"name": "Miri",
	"country": "Malaysia"
	},
	{
	"name": "Shenzhen",
	"country": "China"
	},
	{
	"name": "Hong Kong",
	"country": "Hong Kong"
	},
	{
	"name": "Wuhan",
	"country": "China"
	},
	{
	"name": "Handan",
	"country": "China"
	},
	{
	"name": "Shijiazhuang",
	"country": "China"
	},
	{
	"name": "Bandar Seri Begawan",
	"country": "Brunei"
	},
	{
	"name": "Denpasar",
	"country": "Indonesia"
	},
	{
	"name": "Mandurah",
	"country": "Australia"
	},
	{
	"name": "Perth",
	"country": "Australia"
	},
	{
	"name": "Kota Kinabalu",
	"country": "Malaysia"
	},
	{
	"name": "Beijing",
	"country": "China"
	},
	{
	"name": "Balikpapan",
	"country": "Indonesia"
	},
	{
	"name": "Jinan",
	"country": "China"
	},
	{
	"name": "Tianjin",
	"country": "China"
	},
	{
	"name": "Port Hedland",
	"country": "Australia"
	},
	{
	"name": "Nanjing",
	"country": "China"
	},
	{
	"name": "Makassar",
	"country": "Indonesia"
	},
	{
	"name": "Hangzhou",
	"country": "China"
	},
	{
	"name": "Kaohsiung",
	"country": "Taiwan"
	},
	{
	"name": "Qingdao",
	"country": "China"
	},
	{
	"name": "Taichung",
	"country": "Taiwan"
	},
	{
	"name": "Manila",
	"country": "Philippines"
	},
	{
	"name": "Quezon City",
	"country": "Philippines"
	},
	{
	"name": "Shanghai",
	"country": "China"
	},
	{
	"name": "Taipei",
	"country": "Taiwan"
	},
	{
	"name": "Dalian",
	"country": "China"
	},
	{
	"name": "Iloilo City",
	"country": "Philippines"
	},
	{
	"name": "Zamboanga City",
	"country": "Philippines"
	},
	{
	"name": "Shenyang",
	"country": "China"
	},
	{
	"name": "Tagbilaran",
	"country": "Philippines"
	},
	{
	"name": "Cebu City",
	"country": "Philippines"
	},
	{
	"name": "Changchun",
	"country": "China"
	},
	{
	"name": "Dili",
	"country": "Mali"
	},
	{
	"name": "Pyongyang",
	"country": "North Korea"
	},
	{
	"name": "Davao City",
	"country": "Philippines"
	},
	{
	"name": "Kaesong",
	"country": "North Korea"
	},
	{
	"name": "Harbin",
	"country": "China"
	},
	{
	"name": "Incheon",
	"country": "South Korea"
	},
	{
	"name": "Seoul",
	"country": "South Korea"
	},
	{
	"name": "Wonsan",
	"country": "North Korea"
	},
	{
	"name": "Okinawa",
	"country": "Japan"
	},
	{
	"name": "Ambon",
	"country": "Indonesia"
	},
	{
	"name": "Daegu",
	"country": "South Korea"
	},
	{
	"name": "Busan",
	"country": "South Korea"
	},
	{
	"name": "Yakutsk",
	"country": "Russia"
	},
	{
	"name": "Chongjin",
	"country": "North Korea"
	},
	{
	"name": "Fukuoka",
	"country": "Japan"
	},
	{
	"name": "Darwin",
	"country": "Australia"
	},
	{
	"name": "Vladivostok",
	"country": "Russia"
	},
	{
	"name": "Hiroshima",
	"country": "Japan"
	},
	{
	"name": "Melekeok",
	"country": "Palau"
	},
	{
	"name": "Kobe",
	"country": "Japan"
	},
	{
	"name": "Osaka",
	"country": "Japan"
	},
	{
	"name": "Kyoto",
	"country": "Japan"
	},
	{
	"name": "Nagoya",
	"country": "Japan"
	},
	{
	"name": "Adelaide",
	"country": "Australia"
	},
	{
	"name": "Yokohama",
	"country": "Japan"
	},
	{
	"name": "Kawasaki",
	"country": "Japan"
	},
	{
	"name": "Jayapura",
	"country": "Indonesia"
	},
	{
	"name": "Sapporo",
	"country": "Japan"
	},
	{
	"name": "Geelong",
	"country": "Australia"
	},
	{
	"name": "Hagåtña",
	"country": "United States"
	},
	{
	"name": "Dededo",
	"country": "United States"
	},
	{
	"name": "Melbourne",
	"country": "Australia"
	},
	{
	"name": "Saipan",
	"country": "United States"
	},
	{
	"name": "Cairns",
	"country": "Australia"
	},
	{
	"name": "Townsville",
	"country": "Australia"
	},
	{
	"name": "Port Moresby",
	"country": "Papua New Guinea"
	},
	{
	"name": "Hobart",
	"country": "Australia"
	},
	{
	"name": "Canberra",
	"country": "Australia"
	},
	{
	"name": "Rockhampton",
	"country": "Australia"
	},
	{
	"name": "Magadan",
	"country": "Russia"
	},
	{
	"name": "Wollongong",
	"country": "Australia"
	},
	{
	"name": "Sydney",
	"country": "Australia"
	},
	{
	"name": "Newcastle",
	"country": "United Kingdom"
	},
	{
	"name": "Weno",
	"country": "Federated States of Micronesia"
	},
	{
	"name": "Brisbane",
	"country": "Australia"
	},
	{
	"name": "Gold Coast",
	"country": "Australia"
	},
	{
	"name": "Palikir",
	"country": "Federated States of Micronesia"
	},
	{
	"name": "Honiara",
	"country": "Solomon Islands"
	},
	{
	"name": "Nouméa",
	"country": "New Caledonia"
	},
	{
	"name": "Invercargill",
	"country": "New Zealand"
	},
	{
	"name": "Dunedin",
	"country": "New Zealand"
	},
	{
	"name": "Majuro",
	"country": "Marshall Islands"
	},
	{
	"name": "Christchurch",
	"country": "New Zealand"
	},
	{
	"name": "Wellington",
	"country": "New Zealand"
	},
	{
	"name": "Auckland",
	"country": "New Zealand"
	},
	{
	"name": "Suva",
	"country": "Fiji"
	},
	{
	"name": "Funafuti",
	"country": "Tuvalu"
	},
	{
	"name": "Labasa",
	"country": "Fiji"
	},
	{
	"name": "Nukuʻalofa",
	"country": "Tonga"
	},
	{
	"name": "Rabi Island",
	"country": ""
	},
	{
	"name": "St. Louis",
	"country": "United States"
	},
	{
	"name": "Santiago de Cuba",
	"country": "Cuba"
	},
	{
	"name": "New York City",
	"country": "United States"
	},
	{
	"name": "Port-au-Prince",
	"country": "Haiti"
	},
	{
	"name": "San Carlos de Bariloche",
	"country": "Argentina"
	},
	{
	"name": "Quebec City",
	"country": "Canada"
	},
	{
	"name": "City of Halifax",
	"country": "Canada"
	},
	{
	"name": "Santa Cruz de la Sierra",
	"country": "Bolivia"
	},
	{
	"name": "St. George's",
	"country": "Grenada"
	},
	{
	"name": "Port of Spain",
	"country": "Trinidad and Tobago"
	},
	{
	"name": "Fort-de-France",
	"country": "Martinique"
	},
	{
	"name": "Ciudad del Este",
	"country": "Argentina"
	},
	{
	"name": "São José dos Campos",
	"country": "Brazil"
	},
	{
	"name": "Rio de Janeiro",
	"country": "Brazil"
	},
	{
	"name": "Horta (Azores)",
	"country": "Portugal"
	},
	{
	"name": "Angra do Heroísmo",
	"country": "Portugal"
	},
	{
	"name": "Santa Cruz de Tenerife",
	"country": "Spain"
	},
	{
	"name": "Las Palmas de Gran Canaria",
	"country": "Spain"
	},
	{
	"name": "Cork (city)",
	"country": "Ireland"
	},
	{
	"name": "Marrakech",
	"country": "Morocco"
	},
	{
	"name": "Andorra la Vella",
	"country": "Andorra"
	},
	{
	"name": "Luxembourg (city)",
	"country": "Luxembourg"
	},
	{
	"name": "City of San Marino",
	"country": "San Marino"
	},
	{
	"name": "Vatican City",
	"country": ""
	},
	{
	"name": "Split (city)",
	"country": "Croatia"
	},
	{
	"name": "Gdańsk",
	"country": "Poland"
	},
	{
	"name": "Thessaloniki",
	"country": "Greece"
	},
	{
	"name": "İzmir",
	"country": "Turkey"
	},
	{
	"name": "Chişinău",
	"country": "Moldova"
	},
	{
	"name": "Kiev",
	"country": "Ukraine"
	},
	{
	"name": "Dar es Salaam",
	"country": "Tanzania"
	},
	{
	"name": "Djibouti (city)",
	"country": "Djibouti"
	},
	{
	"name": "Tskhinvali",
	"country": "Georgia"
	},
	{
	"name": "Sana'a",
	"country": "Yemen"
	},
	{
	"name": "Nizhny Novgorod",
	"country": "Russia"
	},
	{
	"name": "Arbil",
	"country": "Iraq"
	},
	{
	"name": "Kuwait City",
	"country": "Kuwait"
	},
	{
	"name": "Malé",
	"country": "Republic of Maldives"
	},
	{
	"name": "Sri Jayawardenapura-Kotte",
	"country": "Sri Lanka"
	},
	{
	"name": "Kolkata",
	"country": "India"
	},
	{
	"name": "Naypyidaw",
	"country": ""
	},
	{
	"name": "Phuket (city)",
	"country": "Thailand"
	},
	{
	"name": "Alor Star",
	"country": "Malaysia"
	},
	{
	"name": "Malacca Town",
	"country": "Malaysia"
	},
	{
	"name": "Huế",
	"country": "Vietnam"
	},
	{
	"name": "Xi'an",
	"country": "China"
	},
	{
	"name": "Yogyakarta (city)",
	"country": "Indonesia"
	},
	{
	"name": "Makati City",
	"country": "Philippines"
	},
	{
	"name": "Koror",
	"country": "Republic of Palau"
	},
	{
	"name": "Tokyo",
	"country": "Japan"
	},
	{
	"name": "Petropavlovsk-Kamchatsky",
	"country": "Russia"
	},
	{
	"name": "Yaren District",
	"country": "Nauru"
	},
	{
	"name": "Port Vila",
	"country": "Vanuatu"
	},
	{
	"name": "South Tarawa",
	"country": ""
	},
	{
	"name": "Mata-Utu",
	"country": "Wallis and Futuna"
	},
	{
	"name": "Anadyr (town)",
	"country": ""
	},
	{
	"name": "Nukulaelae",
	"country": ""
	}
]