// import React, { useState,useEffect } from 'react';
import React from 'react';
import {
   BrowserRouter as Router,
   Route,
   Redirect,
   Switch,
} from "react-router-dom";
import './App.css';
import Header from './components/Header/header'
import Footer from './components/Footer/footer'
import Dashboard from './pages/Jobsearch';
import Homepage from './pages/Homepage';
import Details from './pages/Jobdetails';
import CompanyDetails from './pages/Companydetails';

const App = () => {
   return (
      <React.Fragment>
         <Router>
            <Switch>
               <Route exact path='/'>
                  <Redirect to='/jobs' />
               </Route>
               <Route path='/homepage' >
                    <>
                        <Header />
                        <Homepage/>
                        <Footer />
                    </>
               </Route>
               <Route path='/jobs' >
                    <>
                        <Header />
                        <Dashboard/>
                    </>
               </Route>
               <Route path='/:companyName/:jobTitle' >
                  <>
                        <Header />
                        <Details/>
                    </>
               </Route>
               <Route path='/:company' >
                  <>
                        <CompanyDetails/>
                    </>
               </Route>
            </Switch>
         </Router>
      </React.Fragment>
   );
}

export default App;
