import React from "react";
import JobCard from "../Cards/Jobcard";

export default function JobList({ jobs }) {
	return (
		<div id="jobslist" className="w-100 flex center flex-column ma-2">
			{jobs.length === 0 || jobs === undefined
				? ""
				: jobs.map((data, id) => <JobCard job={data} key={id} />)}
		</div>
	);
}
