import { url } from '../data/base-url';

export const jobs=async(jobsPerPage)=>{
	const clientEndpoint='/jobs/getallclientjobs';
	const partnerEndpoint='/jobs/getallpartnerjobs';
	const AllJobs=[];
	const data=[]
	const clientResponse = await fetch(`${url}${clientEndpoint}`, {
			method:'POST',
			headers: { "Content-Type": "application/json" },
		});

	const partnerResponse = await fetch(`${url}${partnerEndpoint}`, {
			method:'POST',
			headers: { "Content-Type": "application/json" },
		});

	const client=await clientResponse.json();
	const partner=await partnerResponse.json();

	// eslint-disable-next-line array-callback-return
	client.message.map((job)=>{
		job["jobType"]="Direct Client";
		AllJobs.push(job);
	})

	// eslint-disable-next-line array-callback-return
	partner.message.map((job)=>{
	  job["jobType"]="Partner Job"
	  AllJobs.push(job)
	})

	console.log(AllJobs.length)

	// // console.log(AllJobs.length);
	// for(let i=1;i<=Math.ceil(AllJobs.length/jobsPerPage);i++){
	// 	let arr=AllJobs.slice(i*jobsPerPage-jobsPerPage,i*jobsPerPage);
	// 	data.push(arr)
	// }

	// console.log(data.length,AllJobs.length)
	return data
} 

export const clientFilter=async(filters,jobsPerPage)=>{
	const clientEndpoint='/jobs/clientfilter';
	const AllJobs=[];
  const data=[]

	const clientResponse = await fetch(`${url}${clientEndpoint}`, {
		method:'POST',
		headers: { "Content-Type": "application/json" },
    body:JSON.stringify(filters)
	});

	const client=await clientResponse.json();

	// eslint-disable-next-line array-callback-return
	client.message.map((job)=>{
		job["jobType"]="Direct Client"
		AllJobs.push(job);
	})

	console.log(AllJobs.length)

	for(let i=1;i<=Math.ceil(AllJobs.length/jobsPerPage);i++){
    let arr=AllJobs.slice(i*jobsPerPage-jobsPerPage,i*jobsPerPage);
    data.push(arr)
  }

	return data
}


export const PartnerFilter=async(filters,jobsPerPage)=>{
	const partnerEndpoint='/jobs/partnerjobfilter';
	const AllJobs=[];
  const data=[]

	const partnerResponse = await fetch(`${url}${partnerEndpoint}`, {
		method:'POST',
		headers: { "Content-Type": "application/json" },
		body:JSON.stringify(filters)
	});

	const partner=await partnerResponse.json();	

	// eslint-disable-next-line array-callback-return
  partner.message.map((job)=>{
		job["jobType"]="Partner Job"
		AllJobs.push(job);
	})

	for(let i=1;i<=Math.ceil(AllJobs.length/jobsPerPage);i++){
    let arr=AllJobs.slice(i*jobsPerPage-jobsPerPage,i*jobsPerPage);
    data.push(arr)
  }

	return data
}