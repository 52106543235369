/* eslint-disable react-hooks/exhaustive-deps */
import React,{useState,useEffect} from 'react';
import {TextField, Grid} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import {countriesArray,citiesArray,categories,careerLevelOptions} from '../../util/data/static-data';
import {Jobsearch} from '../../util/data/initial-values';
import {clientFilter,PartnerFilter} from '../../util/data/Jobs';
import { sendRequest } from '../../util/helpers/helper-methods';

function JobsearchForm({setfilterlist,setfiltered,setfilters,PerPage,setTotal,filterlist,setloading,seterr,type}) {
	const [inputs,setinputs]=useState(Jobsearch);
	const filterJobs=type==="Direct Client" ? clientFilter : PartnerFilter;
	const getTotalLength=type==="Direct Client"?'/jobs/getallclientjobs':'/jobs/getallpartnerjobs';

	const citiesList=[];
	const countryList=[];

	const handleClick=(e)=>{
		// console.log(inputs)
		setloading(true);
		setfiltered(true);
		filterlist.splice(0,filterlist.length);
		filterJobs(inputs,PerPage)
		.then(res=>{
			// console.log(res)
			if(res.length===0){
				filterlist=null;
			}
			else{
				setfilters(res);
				const val=res[0];
				setfilterlist(val);
				setTotal(res.length);
			}
			setloading(false);
		}).catch(err=>{
			console.log(err);
			seterr(true);
			setloading(false);
		})
	}

	const handleKeyPress=(e)=>{
		if(e.key==='Enter'){
			handleClick();
		}
	}

	const handleReset=()=>{
		setloading(true);
		setfiltered(false);
		filterlist.splice(0,filterlist.length);
		sendRequest(getTotalLength,'POST')
        .then((result)=>{
			// console.log(result)
            setTotal(Math.ceil(result.len/PerPage));
            setloading(false)
        })
	}

	const handleSelectChange=(e)=>{
		// console.log(e.target.name)
		e.persist()
        if(e.target.value===''||e.target.value===null)
            setinputs(inputs => ({...inputs, [e.target.name]: e.target.value}));
        else
            setinputs(inputs => ({...inputs, [e.target.name]: e.target.value}));
	}

	const handleChange=(e)=>{
        e.persist();
        setinputs(inputs => ({...inputs, jobTitle: e.target.value}));
    }

	const handleCityChange=(e,v)=>{
		// console.log(e)
		e.persist();
        if(v===null || v===undefined){
            setinputs(inputs => ({...inputs, city:""}));
        }else
            setinputs(inputs => ({...inputs, city: v}));
    }

	const handleCountryChange=(e,v)=>{
		// console.log(e)
		e.persist();
        if(v===null || v===undefined){
            setinputs(inputs => ({...inputs, country:""}));
        }else
            setinputs(inputs => ({...inputs, country: v}));
    }

	useEffect(() => {
        citiesArray.map((data)=>(
        citiesList.push(data.name)  
        ));
        // console.log(citiesList)
    }, [citiesList])

	useEffect(() => {
		countriesArray.map((data)=>(
		countryList.push(data.name)
		));	
		// console.log(countryList)
	},[countryList])

	return (
		<div className="flex w-70 justify-center"> 
			<Grid container direction="column" alignItems="center" justify="center" spacing={1}>
				<Grid item container direction="row" alignItems="center" justify="center">
					<Grid items md={6} xs>
						<TextField
							className="bg-white br2"
							size="small"
							name="jobTitle"
							variant="outlined"
							fullWidth
							label="Job Title"
							shrink="false"
							onChange={handleChange}
							onKeyPress={handleKeyPress}
							InputLabelProps={{style:{fontSize:14}}}
							value={inputs.jobTitle}  
							inputProps={{style:{fontSize:14,paddingTop:19,paddingBottom:19}}}
						/>
					</Grid>
					<Grid item md={3} xs>
						<p 
							onClick={handleClick}
							style={{background:"#6EB6FF",fontSize:16}}
							className="link pointer flex justify-center items-center pb2 pt2 ma1 br2 white"
						>Search</p>
					</Grid>
					<Grid item md={3} xs>
						<p 
							onClick={handleReset}
							style={{background:"#6EB6FF",fontSize:17}}
							className="link pointer flex justify-center items-center pb2 pt2 ma1 br2 white"
						>Reset</p>
					</Grid>
				</Grid>
				<Grid item container direction="row" alignItems="center" justify="center" spacing={1}>
					<Grid item xs>
						<TextField
							select
							className="bg-white br2"
							size="small"
							variant="outlined"
							fullWidth
							name="careerLevel"
							label="Career Level"
							value={inputs.careerLevel}
							onKeyPress={handleKeyPress}
							onChange={handleSelectChange}
							SelectProps={{
								native: true,
								style:{fontSize:14}
							}}
							InputLabelProps={{style:{fontSize:14}}}
							>
							{
								careerLevelOptions.map((level) => (
									<option key={level.name} value={level.value}>
										{level.name}
									</option>
								))
							}
						</TextField>
					</Grid>
					<Grid item xs>
						<TextField
							select
							className="bg-white br2"
							size="small"
							variant="outlined"
							fullWidth
							name="category"
							label="Category"
							value={inputs.category}
							onKeyPress={handleKeyPress}
							onChange={handleSelectChange}
							SelectProps={{
								native: true,
								placeholder:"Category",
								style:{fontSize:14}
							}}
							InputLabelProps={{style:{fontSize:14}}}
							>
							{
								categories.map((categoryName) => (
									<option key={categoryName} value={categoryName}>
										{categoryName}
									</option>
								))
							}
						</TextField>
					</Grid>
					<Grid item xs>
						<Autocomplete
							options={citiesList}
							onChange={(e,v)=>handleCityChange(e,v)}
							className="bg-white br2 city"
							value={inputs.city} 
							size="small"
							// inputProps={{style:{fontSize:15,paddingTop:15,marginBottom:15}}}
							ListboxProps={{
									style:{fontSize:14}
							}}
							renderInput={(params) => 
									<TextField 
										{...params} 
											InputLabelProps={{style:{fontSize:14}}}	
											// inputProps={{style:{fontSize:15,paddingTop:17,paddingBottom:17}}}
											label="City" 
											fullWidth
											variant="outlined"
									/>
							}
					/>
					</Grid>
					<Grid item xs>
						<Autocomplete
							options={countryList}
							onChange={(e,v)=>handleCountryChange(e,v)}
							className="bg-white br2 city"
							value={inputs.country}
							size="small" 
							// inputProps={{style:{fontSize:12}}}
							ListboxProps={{
									style:{fontSize:14}
							}}
							renderInput={(params) => 
									<TextField 
									{...params} 
											name="country"
											InputLabelProps={{style:{fontSize:14}}}
											// inputProps={{style:{fontSize:15,paddingTop:17,paddingBottom:17}}}
											label="Country" 
											fullWidth
											variant="outlined"
									/>
							}
						/>	
					</Grid>
				</Grid>
			</Grid>
		</div>
	)
}

export default JobsearchForm
