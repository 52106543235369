import React,{ useEffect, useState } from 'react';
import {Avatar} from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
// import LinkIcon from '@mui/icons-material/Link';
import CategoryIcon from '@mui/icons-material/Category';
import PeopleIcon from '@mui/icons-material/People';
// import MoneyIcon from '@mui/icons-material/Money';
import EuroIcon from '@mui/icons-material/Euro';
import BusinessIcon from '@mui/icons-material/Business';
import {goToURL,sendRequest} from '../../util/helpers/helper-methods'
import {useHistory} from 'react-router-dom';

export default function Jobcard({job,id}) {
	const [title,setTitle]=useState("");
	const [company,setCompany]=useState("");
	const [initials,setInitials]=useState(""); 

	const history=useHistory();

	const handleClick=()=>{
		let path=`/${company}/${encodeURIComponent(job.jobTitle)}-${encodeURIComponent(job.jobCode)}?jobType=${job.jobType}&jobid=${job._id}`
		history.push(path);
	}

	useEffect(()=>{
		let temp=job.jobTitle+'-'+job.jobCode
		if(temp.length>30){
			let title=temp.slice(0,30)+'....'
			// console.log(title.length)
			setTitle(title);
		}else setTitle(temp)
	},[job.jobCode, job.jobTitle])

	useEffect(()=>{
		if(job.jobType==="Direct Client"){
			sendRequest('/jobs/getcompanydetails','POST',{companyId:job.company})
			.then((result)=>{
				if(result.success){
					let com=result.message.company
					setCompany(com);
					let ini = job.jobTitle.toUpperCase().split(' ');
					setInitials(ini[0][0]+ini[1][0]);
				}
			})
		}else{
			let com=job.company;
			if(com==="Perfect Jobs - eine Marke von SALES PERFECT GmbH") {
				setCompany("Perfect Jobs");
				let ini=com.toUpperCase();
				setInitials(ini[0]+ini[2]);
			}else {
				setCompany(job.company)
				let ini=com.toUpperCase();
				setInitials(ini[0]+ini[2]);
			}

		}
	},[job.company, job.jobTitle, job.jobType])

	return (
		<div className="card c-shadow bg-white flex-row-l items-center-l flex-column items-center-m flex-column-ns items-start-ns flex w-100 br3 pa2-l pa1-m pa2 ma1 mt3">
			<div className="flex flex-column pa2-l pa1">
				<div className="pa1">
					<p 
						className='ma0 gray f7 f8-m tc f8-mo pa1 ba br2 w-30 w-100-l b' 
						style={{color:"#6EB6FF"}}
						title={job.jobType==='Direct Client'?'This job is posted by our direct client and the applicants are managed directly on the platform':'This jobs is an external offer, managed by some of our partner platforms. If you are interested in applying, please select the Recommendation link and apply externally'}
					>{job.jobType}</p>
				</div>
				<div className="pa1">
					<Avatar variant="rounded" className="b" style={{background:"#6EB6FF",height:98,width:98,fontSize:49}}>{initials}</Avatar>
				</div>
			</div>
			<div className="flex flex-column pa2-l pa1 w-100 items-center-m">
				<p className="ma0 mt1 gray f6 f7-mo f7-m flex items-center" title={job.jobTitle+'-'+job.jobCode}><BusinessCenterIcon fontSize="small" className="pr2 dark-gray"/>{title}</p>
				{job.jobType==="Direct Client"?
					<p 
						className="link pointer ma0 mt1 gray f6 f7-mo f7-m flex items-center"
						onClick={()=>history.push(`/${company}?companyId=${job.company}`)}
					><BusinessIcon fontSize="small" className="pr2 dark-gray"/>{company}</p>
					:
					<p 
						className="link pointer ma0 mt1 gray f6 f7-mo f7-m flex items-center"
					><BusinessIcon fontSize="small" className="pr2 dark-gray"/>{company}</p>
				}
				<p className='ma0 gray f6 mt1 f7-m f7-mo flex items-center'><LocationOnIcon fontSize="small" className="pr2 dark-gray"/>{job.jobType==="Direct Client"?job.city+', '+job.country:job.city}</p>
			</div>
			<div className="flex flex-column pa2-l pa1 w-100 items-center-m">
				{
					job.jobType==="Direct Client"?
					job.jobCategory===undefined|| job.jobCategory===null?'':<p className="ma0 mt1 gray f6 f7-mo f7-m flex items-center"><CategoryIcon fontSize="small" className="pr2 dark-gray"/>{job.jobCategory}</p>:
					job.category===undefined||job.category===null?'':<p className="ma0 mt1 gray f5 f6-mo f6-m flex items-center"><CategoryIcon fontSize="small" className="pr2 dark-gray"/>{job.category}</p>
				}
				{
					job.careerLevel===undefined|| job.careerLevel===null?'':<p className='ma0 gray f6 mt1 f7-m f7-mo flex items-center'><PeopleIcon fontSize="small" className="pr2 dark-gray"/>{job.careerLevel}</p>
				}
				{
					job.jobType==="Direct Client"?<p className='ma0 gray f6 mt1 f7-m f7-mo flex items-center'><EuroIcon fontSize="small" className="pr2 dark-gray"/>{job.from+'-'+job.to+'€'}</p>:''
				}
			</div>
			<div className="flex flex-column-m flex-row-l pa2-l pa1 w-100-l justify-center items-end-l justify-end-l">
				<p
					style={{background:"#6EB6FF"}} 
					onClick={()=>{job.jobType==="Direct Client"?goToURL("https://app.moyyn.com/application"):goToURL(job.recommendationLink)}} 
					className="pointer link c-shadow dib br2 white ma1 pa2 pr4 pl4 f6 f7-m f7-mo"
				>Apply</p>
				<p 
					className="c-shadow link pointer dib br2 bg-dark-gray white ma1 pa2 pr4 pl4 f6 f7-m f7-mo"
					onClick={handleClick}
				>Details</p>
			</div>
			
		</div>
	)
}
