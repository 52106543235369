import React from 'react';
import { 
	Typography,
	Button,
} from '@mui/material';
import {useHistory} from 'react-router-dom';

const ErrorPage = ({ setError }) => {

	let history = useHistory();

	const handleClick = () => {
		setError(false);
		history.push('/jobs');
	}

	return(
		<React.Fragment>
			<Typography align="center" className="w-80" color='textSecondary'>
				There has been an issue while loading your data. 
				Please click 'Next' to go to dashboard or try again later.
				<br/><br/>
					<Button
					onClick={handleClick}
					variant="outlined"
					style={{backgroundColor:"#6EB6FF",color:"white",paddingBottom:"2px"}}
					className="c-shadow dim w-20"
				>
					<Typography>Next</Typography>
				</Button>{" "}
			</Typography>
			{/* <div className="flex justify-end items-end">
					<a className="fixed bottom-1 right-1" href="/feedback" target="_blank" rel="noopener noreferrer">
					<Button
						className='moyynBtn mt4'
					>
						Give us a Feedback
					</Button>
					</a>
			</div> */}
		</React.Fragment>
	)
}

export default ErrorPage;
