import React, { useState, useEffect } from "react";
import { sendRequest, goToURL } from "../util/helpers/helper-methods";
import { useParams } from "react-router-dom";
import { Grid, Avatar } from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import BusinessIcon from "@mui/icons-material/Business";
import CategoryIcon from "@mui/icons-material/Category";
import PeopleIcon from "@mui/icons-material/People";
import EuroIcon from "@mui/icons-material/Euro";
import BuildIcon from "@mui/icons-material/Build";
import HomeWorkIcon from "@mui/icons-material/HomeWork";
import Loading from "../components/Shared/Loading";
import ErrorPage from "../components/Shared/ErrorPageDashboard";
import sanitizeHtml from "sanitize-html-react";

function Jobdetails() {
	const [isloading, setisloading] = useState(false);
	const [error, seterror] = useState(false);
	const [job, setJobDetails] = useState({});
	const [company, setCompany] = useState("");
	const [initials, setInitials] = useState("");
	const [Type, setType] = useState("");
	const [Skills, setSkills] = useState("");
	const [Industries, setIndustries] = useState("");

	const params = useParams();
	const { companyName, jobTitle } = params;

	useEffect(() => {
		let url = new URL(window.location.href);
		const type = url.searchParams.get("jobType");
		const jobid = url.searchParams.get("jobid");
		setType(type);
		setisloading(true);
		sendRequest("/jobs/getjobwithjobid", "POST", { jobId: jobid })
			.then((result) => {
				let val = result.message;
				setJobDetails(val);
				setSkills(val.Skills.join(", "));
				setIndustries(val.Industries.join(", "));
				setisloading(false);
			})
			.catch((err) => {
				console.log(err);
				setisloading(false);
			});
	}, []);

	useEffect(() => {
		if (Type === "" || job.company === undefined) return;
		else {
			if (Type === "Direct Client") {
				sendRequest("/jobs/getcompanydetails", "POST", {
					companyId: job.company,
				})
					.then((result) => {
						if (result.success) {
							let com = result.message.company;
							setCompany(com);
							let ini = com.toUpperCase();
							setInitials(ini[0] + ini[2]);
						}
					})
					.catch((err) => {
						console.log(err);
					});
			}
			// if(Type==="Partner Job"){
			else {
				// console.log(job.company)
				let com = job.company;
				setCompany(job.company);
				let ini = com.toUpperCase();
				setInitials(ini[0] + ini[2]);
			}
		}
	}, [Type, job.company]);

	if (error) {
		return (
			<React.Fragment>
				<div className="flex items-center justify-center bg-white ma3 br2 vh-75">
					<ErrorPage setError={seterror} />
				</div>
			</React.Fragment>
		);
	}

	if (isloading) {
		return (
			<div className="flex items-center justify-center bg-white ma3 br2 vh-75">
				<Loading text="Loading Details ....." />
			</div>
		);
	}

	return (
		<div className="ma4-l ma4-m ma2">
			<div className="flex flex-1 flex-column flex justify-center items center w-100 w-90-l mt3">
				<div className="card c-shadow bg-white flex-row-l items-center-l flex-column items-center-m flex-column-ns items-start-ns flex w-100 br3 pa2-l pa1-m pa1 ma1 mt3">
					<div className="flex flex-column pa2-l pa1">
						<div className="pa1">
							<p
								className="ma0 gray f7 f8-m tc f8-mo pa1 ba br2 w-30 w-100-l"
								style={{ color: "#6EB6FF" }}
								title={
									job.jobType === "Direct Client"
										? "This job is posted by our direct client and the applicants are managed directly on the platform"
										: "This jobs is an external offer, managed by some of our partner platforms. If you are interested in applying, please select the Recommendation link and apply externally"
								}>
								{Type}
							</p>
						</div>
						<div className="pa1">
							<Avatar
								variant="rounded"
								className="b"
								style={{
									background: "#6EB6FF",
									height: 98,
									width: 98,
									fontSize: 49,
								}}>
								{initials}
							</Avatar>
						</div>
					</div>
					<div className="w-100">
						<div>
							<h3 className="mt0 ma2 gray f6 f5-m f4-l">{decodeURIComponent(jobTitle)}</h3>
						</div>
						<div className="flex-row-l items-center-l flex-column items-center flex-column-ns items-start-ns flex ma1 ma0-m">
							<div className="flex flex-column pa2-l pa1 w-100 items-center-m">
								{job.jobType === "Direct Client" ? (
									<a
										className="link pointer ma0 mt1 gray f6 f7-mo f7-m flex items-center"
										href = {`/${companyName}?companyId=${job.company}`}
										>
										<BusinessIcon
											fontSize="small"
											className="pr2 dark-gray"
										/>
										{company}
									</a>
								) : (
									<a
										className="link pointer ma0 mt1 gray f6 f7-mo f7-m flex items-center"
										href = {`/${companyName}?companyId=${job.company}`}
										>
										<BusinessIcon
											fontSize="small"
											className="pr2 dark-gray"
										/>
										{company}
									</a>
								)}
								<p className="ma0 gray f6 mt1 f7-m f7-mo flex items-center">
									<LocationOnIcon
										fontSize="small"
										className="pr2 dark-gray"
									/>
									{job.jobType === "Direct Client"
										? job.city + ", " + job.country
										: job.city}
								</p>
							</div>
							<div className="flex flex-column pa2-l pa1 w-100 items-center-m">
							{Type === "Direct Client" ? (
									job.jobCategory === undefined ||
									job.jobCategory === null ? (
										""
									) : (
										<p className="ma0 mt1 gray f6 f7-mo f7-m flex items-center">
											<CategoryIcon
												fontSize="small"
												className="pr2 dark-gray"
											/>
											{job.jobCategory}
										</p>
									)
								) : job.category === undefined ||
								  job.category === null ? (
									""
								) : (
									<p className="ma0 mt1 gray f5 f6-mo f6-m flex items-center">
										<CategoryIcon
											fontSize="small"
											className="pr2 dark-gray"
										/>
										{job.category}
									</p>
								)}
								{job.careerLevel === undefined ||
								job.careerLevel === null ? (
									""
								) : (
									<p className="ma0 mt1 gray f5 f6-mo f6-m flex items-center">
										<PeopleIcon
											fontSize="small"
											className="pr2 dark-gray"
										/>
										{job.careerLevel}
									</p>
								)}
								{Type === "Direct Client" ? (
									<p className="ma0 mt1 gray f5-l f6 f6-m flex items-center">
										<EuroIcon
											fontSize="small"
											className="pr2 dark-gray"
										/>
										{job.from + "-" + job.to + "€"}
									</p>
								) : (
									""
								)}
							</div>
							<div className="flex flex-column pa2-l pa1 w-100 items-center-m">
								{Type === "Direct Client" ? (
									<p className="ma0 mt1 gray f6 f7-m f7-mo flex items-center">
										<BuildIcon
											fontSize="small"
											className="pr2 dark-gray"
										/>
										{Skills}
									</p>
								) : (
									""
								)}
								{Type === "Direct Client" ? (
									<p className="ma0 mt1 gray f6 f7-m f7-mo flex items-center">
										<HomeWorkIcon
											fontSize="small"
											className="pr2 dark-gray"
										/>
										{Industries}
									</p>
								) : (
									""
								)}
							</div>
							<div className="flex flex-column-m flex-row-l pa2-l pa1 w-100-l items-end-l justify-end-l">
								<p
									style={{ background: "#6EB6FF" }}
									onClick={() => {
										Type === "Direct Client"
											? goToURL(
													"https://app.moyyn.com/application"
											  )
											: goToURL(job.recommendationLink);
									}}
									className="pointer link c-shadow dib br2 white ma1 w4 pa2 pr4 pl4 f6 f7-m f7-mo flex justify-center">
									Apply
								</p>
							</div>
						</div>
					</div>
				</div>
				<div className="card c-shadow bg-white flex-column flex w-100 br3 pa2-l pa1-m pa1 ma1 mt3">
					<div className="flex flex-column pa2-l pa1">
						<p className="ma2 f4-l mb2 f5-m f5 pb2 gray b tl">
							Job Description
						</p>
						<Grid container spacing={1}>
							<p className="ma2 gray mb1 f5-l f6-m f6 tl ph2">
								<div
									dangerouslySetInnerHTML={{
										__html: sanitizeHtml(job.description),
									}}
								/>
							</p>
						</Grid>
					</div>
					<div className="flex flex-column pa2-l pa1">
						<p className="ma2 f4-l mb2 f5-m f5 pb2 gray b tl">
							Job Requirements
						</p>
						<Grid container spacing={1}>
							<p className="ma2 gray mb1 f5-l f6-m f6 tl ph2">
								<div
									dangerouslySetInnerHTML={{
										__html: sanitizeHtml(job.requirements),
									}}
								/>
							</p>
						</Grid>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Jobdetails;
