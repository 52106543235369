import React,{useState, useEffect} from 'react';
import Loading from '../components/Shared/Loading';
import ErrorPage from '../components/Shared/ErrorPageDashboard';
import Joblist from '../components/List/Joblist';
import { sendRequest } from '../util/helpers/helper-methods';
import { nodeClientURL } from '../util/data/base-url';

export default function Companydetails() {
  const [isloading,setisloading]=useState(false);
	const [error,seterror]=useState(false);
  const [jobs,setjobs]=useState([]);
	const [companyId,setCompanyId]=useState("");
	const [companyName,setCompanyName]=useState("");
  const [image, setImage] = useState(null);
  const [initialValues, setInitialValues] = useState(null);

  useEffect(()=>{
    let url= new URL(window.location.href);
		const companyId= url.searchParams.get("companyId");
    setCompanyId(companyId);
  },[])

  useEffect(()=>{
    if(companyId==="") return;
    sendRequest('/jobs/getcompanydetails','POST',{companyId:companyId})
    .then(res=>{
      if(res.success){
        setCompanyName(res.message.company);
      }
    })
  },[companyId])

  useEffect(()=>{
    if(!companyId) return;
    setisloading(true);
    sendRequest('/jobs/getcompanyjobs','POST',{companyId:companyId})
    .then(res=>{
      res.message.forEach(job=>{
        job["jobType"]="Direct Client"
        setjobs(prev=>[...prev,job])
      })
      fetch(nodeClientURL + "/company/page/fetch", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          companyId: companyId,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.success) {
            setInitialValues(data);
            setImage(data.logo);
          }
        });
      setisloading(false);
    })
    .catch(err=>{
      console.log(err);
      seterror(true);
    })
  },[companyId])

  if (error) {
    return(
        <React.Fragment>
            <div className='flex items-center justify-center bg-white ma3 br2 vh-75'>
                <ErrorPage setError={seterror} />
            </div>
        </React.Fragment>
    )
  }

  if (isloading) {
      return(
          <div className='flex items-center justify-center bg-white ma3 br2 vh-75'>
              <Loading text='Loading Details .....' />
          </div>
      )
  }

  return (
    <div className="ma4-l ma4-m ma2">
      <div className='pa4 bg-white br4'>
        <div className='flex items-center w-100'>
        <p className='f4-l ml2 f4-5 f7 pb1 b gray tl'>{companyName}'s Career Page</p>
        </div>
        <div className='flex justify-between items-start'>
          {image && <img height={180} className='mr-auto' src={image} alt='logo' />}
          <div className='w-100'>
            {console.log(initialValues)}
            {initialValues && Object.keys(initialValues).length > 1 && (
              <div className='pa2 pt0 ml4 w-100'>
                {(initialValues?.about && initialValues?.about !== "<p><br></p>") && (
                  <div>
                    <p className='f4-l f7 pb1 b gray tl ma0 mb2'>About {companyName}</p>
                    <div style={{wordWrap:'break-word', maxWidth:'78%'}} dangerouslySetInnerHTML={{ __html: initialValues.about }} />
                  </div>
                )
                }
                {(initialValues?.benefits && initialValues?.benefits !== "<p><br></p>") && (
                  <div>
                    <p className='f4-l f7 pb1 b gray tl ma0 mb2'>{companyName}'s Benefits</p>
                    <div style={{wordWrap:'break-word', maxWidth:'78%'}} dangerouslySetInnerHTML={{ __html: initialValues.benefits }} />
                  </div>
                )
                }
                {(initialValues?.workCulture  && initialValues?.workCulture !== "<p><br></p>") && (
                  <div>
                    <p className='f4-l f7 pb1 b gray tl ma0 mb2'>{companyName}'s Work Culture</p>
                    <div style={{wordWrap:'break-word', maxWidth:'78%'}} dangerouslySetInnerHTML={{ __html: initialValues.workCulture }} />
                  </div>
                )
                }
              </div>
            )}
          </div>
        </div>
      </div>
      <p className='ma3 f4-l mb2 f4-5 f7 pb1 b gray tl'>All Jobs from <span style={{color:"#6EB6FF"}}>{companyName}</span></p>
      <div className="flex justify-center items-center">
      {
				jobs.length === 0 ? <p className='flex justify-center items-center w-100 br3 bg-white h4 gray tc f4-l f4-m f5'>No Jobs to show</p>
        :
        <div className="flex-1 ph2 mr2 w-100 w-80-l flex justify-center items-center">
            <Joblist jobs={jobs}/>
        </div>
			}
      </div>
    </div>
  )
}
