import React, { useState, useEffect } from "react";
import Pagination from "@mui/material/Pagination";
import Joblist from "../components/List/Joblist";
import Loading from "../components/Shared/Loading";
import ErrorPage from "../components/Shared/ErrorPageDashboard";
import Filters from "../components/DashboardElement/JobsearchForm";
import { sendRequest } from "../util/helpers/helper-methods";
import "../styles/dashboard.scss";

function Jobsearch() {
	const [filters, setfilters] = useState([]);
	const [isloading, setisloading] = useState(false);
	const [filterloading, setfilterloading] = useState(false);
	const [error, seterror] = useState(false);
	const [joblist, setjoblist] = useState([]);
	const [filterlist, setfilterlist] = useState([]);
	const [filtered, setfiltered] = useState(false);
	const [TotalPages, setTotalPages] = useState(1);
	const [currentPage, setCurrentPage] = useState(1);

	const jobsPerPage = 30;

	const changeCurrentPage = (event, value) => {
		try {
			setCurrentPage(value);
			if (filtered) {
				setfilterlist(filters[value - 1]);
			} else {
				joblist.splice(0, joblist.length);
				setisloading(true);
				sendRequest("/jobs/getallclientjobs", "POST", {
					page: value - 1,
				}).then((res) => {
					// eslint-disable-next-line array-callback-return
					res.message.map((job) => {
						job["jobType"] = "Direct Client";
						setjoblist((prev) => [...prev, job]);
					});
					setisloading(false);
				});
			}
		} catch (err) {
			console.log(err);
		}
	};

	useEffect(() => {
		setisloading(true);
		sendRequest("/jobs/getallclientjobs", "POST")
			.then((res) => {
				setjoblist((prev) => [
					...prev,
					...res.message.map((job) => ({
						...job,
						jobType: "Direct Client",
					})),
				]);
				setTotalPages(Math.ceil(res.len / jobsPerPage));
				setisloading(false);
			})
			.catch((err) => {
				console.log(err);
				setisloading(false);
				seterror(true);
			});
	}, []);

	if (error) {
		return (
			<React.Fragment>
				<div className="flex items-center justify-center bg-white ma3 br2 vh-75">
					<ErrorPage setError={seterror} />
				</div>
			</React.Fragment>
		);
	}

	if (isloading || filterloading) {
		return (
			<div className="flex items-center justify-center bg-white ma3 br2 vh-75">
				<Loading text="Loading Jobs ....." />
			</div>
		);
	}

	return (
		<div className="ma4-l ma4-m ma2">
			<p className="ma1 f2-l mb3 mt5 f3-m f5 pb4 gray b tc">
				Find your Next Jobs{" "}
				<span style={{ color: "#6EB6FF" }}>Today</span>
			</p>
			<div className="flex justify-center items-center">
				<Filters
					setfilterlist={setfilterlist}
					setfiltered={setfiltered}
					setfilters={setfilters}
					PerPage={jobsPerPage}
					setTotal={setTotalPages}
					filterlist={filterlist}
					setloading={setfilterloading}
					seterr={seterror}
					type="Direct Client"
				/>
			</div>
			{joblist.length === 0 || (filterlist.length === 0 && filtered) ||
			TotalPages <= 1 ? (
				""
			) : (
				<div className="flex justify-center items-center ma4">
					<Pagination
						count={TotalPages}
						size="small"
						showFirstButton
						shape="rounded"
						showLastButton
						defaultPage={1}
						page={currentPage}
						onChange={changeCurrentPage}
						boundaryCount={2}
					/>
				</div>
			)}
			{joblist.length === 0 || (filterlist.length === 0 && filtered) ? (
				<p className="flex justify-center items-center w-100 br3 bg-white h4 gray tc f4-l f4-m f5">
					No Jobs to show
				</p>
			) : (
				<div
					className={` flex-1 ph2 mr2 w-100 w-80-l flex justify-center items center`}>
					<Joblist jobs={filtered ? filterlist : joblist} />
				</div>
            )}
			{joblist.length === 0 || (filterlist.length === 0 && filtered) ||
			TotalPages <= 1 ? (
				""
			) : (
				<div className="flex justify-center items-center ma4">
					<Pagination
						count={TotalPages}
						size="large"
						showFirstButton
						shape="rounded"
						showLastButton
						defaultPage={1}
						page={currentPage}
						onChange={changeCurrentPage}
						boundaryCount={2}
					/>
				</div>
			)}
		</div>
	);
}

export default Jobsearch;
