import React from 'react';
import {Grid} from '@mui/material';

function JobcategoryCard({category,id}) {
  return (
    <div className="c-shadow bg-white pa3-l pa3-m pa2 br3 ">
      <Grid container direction="column" alignItems="flex-start">
        <Grid item>
          <p>{category.jobCategory}</p>
          <p className="f7">{`Total Jobs:${category.totalJobs}`}</p>
        </Grid>
      </Grid>
    </div>
    // <Grid item xs md={3} className="bg-white h3 br3">
    //   <p className="flex justify-start items center tc">{category.jobCategory}</p>
    //   <p className="f8">{category.totalJobs}</p>
    // </Grid>
  )
}

export default JobcategoryCard
